/* eslint-disable */
import { ExpandMore } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Accordion, AccordionDetails, AccordionSummary, Button, Divider, Grid, Typography, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import BottomComponent from '../../Components/UI/bottomComponent';
import { getAxiosHeader, getVerificationFromProfile, uploadFile } from '../../Config/api';
import { axiosInstanceWithoutBaseUrl } from '../../Config/axios';
import { apiEndpoints } from '../../Config/routes';
import { UIStore, updateStore } from '../../Config/store';
import { mixpanel, trackingEvents } from '../../Monitoring/Mixpanel';
import { statesEnum } from '../../Utils/enums';
import OldData from '../DocResponse/oldData';
import Form from './form';


export default function Standalone({ edit, data, skip }) {
  const { state } = useLocation();
  const theme = useTheme()
  const navigate = useNavigate();
  const currentWorkingStepperStep = UIStore.useState((s) => s.currentWorkingStepperStep);
  const formResponses = UIStore.useState((s) => s.formResponses);
  const form = UIStore.useState((s) => s.form);
  const formInfo = UIStore.useState((s) => s.formInfo);
  const docData = UIStore.useState((s) => s.docData);
  const searchedQuery = UIStore.useState((s) => s.searchedQuery);
  const currentCredentialUnderFetch = form?.list?.[currentWorkingStepperStep];
  const [page, setPage] = useState(0);
  const [oldItemsFound, setOldItemsFound] = useState(null);
  const [oldFailedItemsFound, setOldFailedItemsFound] = useState(null);
  const [loading, setLoading] = useState(null);
  const [selectedOldData, setSelectedOldData] = useState({});
  const [subTypes, setSubTypes] = useState({});
  const [subTypesWithEmptyFiles, setSubTypesWithEmptyFiles] = useState([]);

  console.log("currentCredentialUnderFetch", currentCredentialUnderFetch, subTypes)

  const getDataFromProfile = async (wallet) => {
    setPage(0);
    updateStore('formResponses', {});

    setOldItemsFound(null);
    let mutatedValue = { ...currentCredentialUnderFetch };
    mutatedValue.key = currentCredentialUnderFetch?.key?.includes("-marksheet") ? "bgv-degree" : currentCredentialUnderFetch?.key;

    console.log("mutatedValue", mutatedValue)

    if (currentCredentialUnderFetch?.data?.acceptMultipleSubmissions) {

      await getVerificationFromProfile(mutatedValue, wallet)
        .then((z) => {
          // if (z?.bgvDetails) {
          if (z) {

            const filtered = z?.reduce((acc, item) => {

              if (item.subType) {
                const dataOnForm = docData.data?.find(x => x.subType === item.subType)
                if (!form?.list?.[currentCredentialUnderFetch?.key]?.closed || dataOnForm) {
                  if (item?.state !== statesEnum.FAILED) {
                    acc.notFailed.push({
                      dataOnForm,
                      dataOnProfile: item,
                      subType: item.subType
                    })
                  } else {
                    acc.failed.push({
                      dataOnForm,
                      dataOnProfile: item,
                      subType: item.subType
                    })
                  }
                }
              }
              return acc;
            }, {
              failed: [],
              notFailed: []
            });
            console.log('filtered: z', filtered, state);
            setOldItemsFound([...filtered.notFailed]);
            setOldFailedItemsFound([...filtered.failed]);
            setSubTypes(filtered.notFailed?.reduce((acc, x) => {
              if (x?.subType) {
                acc[x.subType] = state?.subTypes?.[x.subType] ?? "";
              }
              return acc;
            }, {}));
          }
          // setOldData(z?.bgvDetails?.ongrid?.data);
          // }
        });
    } else {
      getVerificationFromProfile(mutatedValue)
        .then((z) => {
          // if (z?.bgvDetails) {
          if (z) {
            const filtered = z?.reduce((acc, item) => {
              if (item?.type === mutatedValue.key &&
                item.state !== statesEnum.NOT_INITIALIZED) {
                // const dataOnForm = docData.data?.find(x => x.subType === item.subType)
                acc.push({
                  // dataOnForm,
                  dataOnProfile: item,
                  subType: item.subType
                })
              }
              return acc;
            }, []);

            console.log('filtered: z', filtered);
            setOldItemsFound([...filtered]);
            setSubTypes(filtered?.reduce((acc, x) => {
              if (x.subType && x?.dataOnProfile?.state !== statesEnum.FAILED) {
                acc[x.subType] = state?.subTypes?.[x.subType] ?? '';
              }
              return acc;
            }, {}));
          }
          // setOldData(z?.bgvDetails?.ongrid?.data);
          // }
        });
    }

    // updateStore('fieldContent', {});
    if (state?.subTypes) {
      setSelectedOldData(state?.subTypes);
    }
  }
  useEffect(() => {
    getDataFromProfile()
  }, [currentCredentialUnderFetch]);

  console.log('oldItemsFound, state:22, subtypes::', oldItemsFound, state, subTypes, subTypesWithEmptyFiles);

  const submit = async () => {
    setLoading(true);
    try {
      const c = currentCredentialUnderFetch?.data?.authOptions?.[0]?.page?.config?.[0];

      const uploadedFile = [];
      for await (const x of Object.keys(selectedOldData)) {
        if (formInfo?.[currentCredentialUnderFetch.key]?.marksheetRequired) {

          let link = null;

          if (typeof selectedOldData[x] === 'string') {
            link = selectedOldData[x];
          } else {
            link = await uploadFile(selectedOldData[x], {
              ...(currentCredentialUnderFetch?.key.includes('bgv-') ? {
                folderName: currentCredentialUnderFetch?.key
              } : {}),
              // imageHasDocument: true,
            });
          }
          if (link) {
            uploadedFile.push({ label: 'Marksheet', url: link });
          } else {
            window.snackbar('error', 'Unable to upload file');
            mixpanel.track(trackingEvents.verification.events.uploadFileWithOldVerification, {
              category: currentCredentialUnderFetch?.category?.name,
              name: currentCredentialUnderFetch?.name,
              status: 'ABORT',
              reason: `Unable to upload marksheet file directly:: link:: ${link} :: typeof :: ${typeof selectedOldData[x]} :: index :: ${x}`,
              link,
              orgId: searchedQuery?.orgId,
              txnId: searchedQuery?.txnId
            });
            return null;
          }

        }
      }
      mixpanel.track(trackingEvents.verification.events.allDone, {
        category: currentCredentialUnderFetch?.category?.name,
        name: currentCredentialUnderFetch?.name,
        status: 'SUCCESS',
        reuse: true,
        orgId: searchedQuery?.orgId,
        txnId: searchedQuery?.txnId
      });

      await axiosInstanceWithoutBaseUrl(`${c?.api?.endpointBaseUrl ?
        apiEndpoints[c?.api?.endpointBaseUrl](currentCredentialUnderFetch.key) :
        c?.api?.endpoint}`, {
        method: c.api.method.toUpperCase(),
        data: {
          'verificationName': currentCredentialUnderFetch.key,
          'transactionId': searchedQuery?.txnId,
          'orgId': searchedQuery?.orgId,
          'subTypeList': Object.keys(selectedOldData),
          ...(uploadedFile.length > 0 ? {
            'marksheetList': uploadedFile
          } : {})
        },
        ...getAxiosHeader({})
      }).then((res) => {
        if (res?.data?.code === 0) {
          navigate(-1);
        }
      });
    } catch (e) {
      console.log('Error', e);
    }

    setLoading(false);
  };

  console.log('standalone:',
    // !(currentCredentialUnderFetch?.data?.acceptMultipleSubmissions ||
    oldItemsFound?.length <= 0)
  return (
    <Grid
      container
      sx={{
        padding: '1em',
      }}>
      {/* <Wave image={currentCredentialUnderFetch?.icon} /> */}
      {/* <WorkingStepper /> */}

      {((currentCredentialUnderFetch.key.includes('bgv-') &&
        !(currentCredentialUnderFetch?.data?.acceptMultipleSubmissions ||
          (oldItemsFound ? oldItemsFound?.length <= 0 : true))) &&
        !edit) ? <Divider fullWidth sx={{ width: '100%' }}>
        <Typography
          variant="body1" sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center'
          }}>
          Saved Data
        </Typography>
      </Divider> : null}

      {(currentCredentialUnderFetch.key.includes('bgv-') && oldItemsFound?.length > 0 && !edit) ?
        oldItemsFound?.map((zz, i) => <OldData
          key={'oldItemsFound-' + zz?.subType + '-' + i}
          index={i}
          oldData={zz.dataOnProfile}
          dataOnForm={zz.dataOnForm}
          setSelectedOldData={setSelectedOldData}
          selectedOldData={selectedOldData}
          setSubTypes={setSubTypes}
          subTypes={subTypes}
          subTypesWithEmptyFiles={subTypesWithEmptyFiles}
          fn={getDataFromProfile}
        />) : null}


      {oldFailedItemsFound?.length > 0 && !edit ?
        <Accordion sx={{
          'outline': 'none',
          marginTop: '1em',
          border: `1px solid ${theme.palette.grey.main}`,
          borderRadius: '8px',
          boxShadow: 'none',
          width: '100%'
        }}
          defaultExpanded={oldItemsFound?.length === 0}>
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="failed-documents-content"
            id="failed-documents-header"
          >
            <Typography
              variant="h6" color="error">Failed Documents</Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ marginTop: 0 }}>
            {(currentCredentialUnderFetch.key.includes('bgv-') && oldFailedItemsFound?.length > 0 && !edit) ?
              oldFailedItemsFound?.map((zz, i) => <OldData
                key={'oldItemsFound-' + zz?.subType + '-' + i}
                index={i}
                oldData={zz.dataOnProfile}
                dataOnForm={zz.dataOnForm}
                setSelectedOldData={setSelectedOldData}
                selectedOldData={selectedOldData}
                setSubTypes={setSubTypes}
                subTypes={subTypes}
                subTypesWithEmptyFiles={subTypesWithEmptyFiles}
                fn={getDataFromProfile}
              />) : null}
          </AccordionDetails>
        </Accordion> : null}


      {(!currentCredentialUnderFetch?.data?.acceptMultipleSubmissions && (!oldItemsFound ||
        (oldItemsFound ? oldItemsFound?.length <= 0 : true))) ||
        (currentCredentialUnderFetch?.data?.acceptMultipleSubmissions) ||
        edit ? <Form
        edit={edit}
        c={data.page.config}
        data={data}
        setPage={setPage}
        page={page}
        formResponses={formResponses}
        setFormResponses={(q) => updateStore('formResponses', q)}
        skip={skip}
        subTypes={subTypes}
        setSubTypes={setSubTypes}
        setSubTypesWithEmptyFiles={setSubTypesWithEmptyFiles}
        oldItems={oldItemsFound ?? []}
        getDataFromProfile={getDataFromProfile} /> : null}

      {(currentCredentialUnderFetch.key.includes('bgv-') &&
        ![form?.state, formInfo[currentCredentialUnderFetch?.key]?.state].includes(statesEnum.CLOSED) &&
        !(currentCredentialUnderFetch?.data?.acceptMultipleSubmissions ||
          (oldItemsFound ? oldItemsFound?.length <= 0 : true))) && !edit ? <BottomComponent
            sx={{
              // backgroundColor: '#ffffff',
              // position: 'fixed',
              // bottom: '1px',
              // left: 0,
              // zIndex: 99999,
              padding: '1em 0.5em 0 0.5em',
              // width: MAX_WIDTH
            }}>
        {formInfo[currentCredentialUnderFetch?.key]?.skipAllowed && !formInfo[currentCredentialUnderFetch?.key].markedAsSkip
          && (
            formInfo[currentCredentialUnderFetch?.key]?.state === statesEnum.NOT_INITIALIZED ||
            formInfo[currentCredentialUnderFetch?.key]?.state === statesEnum.DATA_SAVED
          ) ? <Button
            variant="text"
            color="brand"
            // loading={loadingSkip}
            sx={{ width: '30%' }}
            onClick={async () => {
              skip();
            }}>
          Skip
        </Button> : null}
        <LoadingButton
          loading={loading}
          variant="contained"
          color={'primary'}
          sx={{ width: '96%', margin: 'auto' }}
          datacy="submit"
          onClick={async () => { submit(); }}>
          Submit to Form
        </LoadingButton>
      </BottomComponent> : null}

      {/* {(currentCredentialUnderFetch.key.includes('bgv-')
        && oldItemsFound?.length > 0 && !edit
        && currentCredentialUnderFetch?.data?.acceptMultipleSubmissions) ?
        <BottomComponent
          sx={{
            // backgroundColor: '#ffffff',
            // position: 'fixed',
            // bottom: '1px',
            // left: 0,
            // zIndex: 99999,
            // width: MAX_WIDTH
          }}>
          <Button
            variant="outlined" color="primary"
            sx={{ width: '96%', margin: 'auto' }}
            onClick={async () => {
              navigate(-1);
            }}>
            Back
          </Button>
        </BottomComponent> : null} */}
    </Grid>
  );
}
