
import { intervalToDuration } from 'date-fns';
import { debounce } from 'lodash';
import React, { useCallback, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { createWallet, loginCall, otpCall } from '../../../../../../../Config/api';
import { appendLoggedIn } from '../../../../../../../Helper/helpers';
import { mixpanel, trackingEvents } from '../../../../../../../Monitoring/Mixpanel';
import { refreshAccessTokenAtRegularInterval } from '../../../../../../../Utils/tokenUtils';

const minDigitsInPhoneNumber = 4;

function useAuth() {
  const navigate = useNavigate();
  const { state: locationState } = useLocation();
  const [phNo, setPhNo] = React.useState('');
  const [countryCode, setCountryCode] = React.useState('+91');
  const [errors, setErrors] = React.useState({});
  const [txId, setTxId] = React.useState('');
  const [isLoading, setIsLoading] = React.useState({ requestOTP: false, resendOTP: false, submitOTP: false });
  const [step, setStep] = React.useState(0);
  const [conditionsAgreed, setConditionsAgreed] = React.useState(false);
  const [otp, setOtp] = React.useState('');
  const timerRef = React.useRef(null);
  const phoneRef = useRef();
  const [otpTimeoutSeconds, setOtpTimeoutSeconds] = React.useState(process.env.REACT_APP_LOGIN_TIMER_SECONDS);
  const [accountExists, setAccountExists] = React.useState(true);
  const [trackFields, setTrackFields] = React.useState([]);
  const [flagId, setFlagId] = React.useState('IN');

  useEffect(() => {
    if (step === 1) {
      timerRef.current = setInterval(() => setOtpTimeoutSeconds((prev) => prev - 1), 1000);
    }
    return () => clearInterval(timerRef.current);
  }, [step]);

  useEffect(() => {
    if (otpTimeoutSeconds === 0 && timerRef.current) clearInterval(timerRef.current);
  }, [otpTimeoutSeconds]);

  const getTimeoutTime = () => {
    const time = intervalToDuration({ start: 0, end: otpTimeoutSeconds * 1000 });
    const minutes = (time.minutes?.toString()?.length === 1 ? `0${time.minutes}` : time.minutes) ?? '00';
    const seconds = (time.seconds?.toString()?.length === 1 ? `0${time.seconds}` : time.seconds) ?? '00';
    return `${minutes}  : ${seconds}`;
  };

  const handleRequestOTP = useCallback(debounce(async (q = phNo) => {
    setIsLoading((prev) => ({ ...prev, requestOTP: true }));
    setTrackFields([]);
    mixpanel.track(trackingEvents.auth.events.sign.in.started, {
      username: `${countryCode}-${q}`
    });
    loginCall(`${countryCode}-${q}`)
        .then((z) => {
          console.log('z', z);
          if (z?.code === 0) {
            mixpanel.track(trackingEvents.auth.events.sign.in.requestedOTP, {
              username: `${countryCode}-${q}`
            });
            setTxId(z?.data?.txId);
            setAccountExists(true);
            setTrackFields(['otp']);
            setStep(1);
          } else if (z?.code === 105) {
            mixpanel.track(trackingEvents.auth.events.sign.in.requestedOTP, {
              username: `${countryCode}-${q}`
            });
            setStep(1);
            setTrackFields(['name', 'otp']);

            const id = '';
            mixpanel.track(trackingEvents.auth.events.sign.up.started, {
              username: `${countryCode}-${q}`
            });
            createWallet(id, `${countryCode}-${q}`)
                .then((z) => {
                  console.log(z);
                  setTxId(z?.data?.txId);
                  if (z) {
                    mixpanel.track(trackingEvents.auth.events.sign.up.requestedOTP, {
                      username: `${countryCode}-${q}`
                    });
                    // setCurrentState('otp');
                    setTrackFields(['name', 'otp']);
                    setAccountExists(false);
                    // setOtp('');
                    // setOtpSentTime(new Date());
                    // setEnableResendOtp(false);
                    // setTimeout(() => {
                    //   countDownRef?.current?.start();
                    // }, 1000);
                  }
                }).finally((z) => {
                  //   setLoading(false);
                });
          }
        }).finally((z) => {
          //   setLoading(false);
          setIsLoading((prev) => ({ ...prev, requestOTP: false }));
        });
  }, 1000), [phNo, countryCode]);

  const startReqOTP = () => {
    const tempErrors = {};
    if (phNo?.length < minDigitsInPhoneNumber) tempErrors.phNo = `At least ${minDigitsInPhoneNumber} digits are needed.`;
    if (Object.keys(tempErrors).length > 0) return setErrors(tempErrors);
    setIsLoading((prev) => ({ ...prev, requestOTP: true }));
    handleRequestOTP();
  };
  const handleEnterKeyPress = (e) => {
    const code = e.keyCode || e.which;
    if (code === 13 && conditionsAgreed && otp?.length === 6 && !isLoading?.submitOTP) submitOtp();
  };

  const handleResendOtp = () => {
    // setLoading('Resend')
    (accountExists ? loginCall(`${countryCode}-${phNo}`) : createWallet(name, `${countryCode}-${phNo}`))
        .then((z) => {
          mixpanel.track(trackingEvents.auth.events.sign.up.started, {
            username: `${countryCode}-${phNo}`
          });
          console.log('z', z);
          if (z?.code === 0) {
            mixpanel.track(trackingEvents.auth.events.sign.up.requestedOTP, {
              username: `${countryCode}-${phNo}`
            });
            setTxId(z?.data?.txId);
            window.snackbar('success', 'OTP sent successfully');
            setOtpTimeoutSeconds(30);
            timerRef.current = setInterval(() => {
              setOtpTimeoutSeconds((prev) => prev - 1);
            }, 1000);
          }
        }).finally((z) => {
          // setLoading(false)
        });
  };

  const submitOtp = () => {
    setIsLoading((prev) => ({ ...prev, submitOTP: true }));
    otpCall(txId, otp)
        .then(async (x) => {
          if (x) {
            mixpanel.track(trackingEvents.auth.events.sign.in.completed, {
              username: `${countryCode}-${phNo}`
            });
            mixpanel.identify(`${countryCode}-${phNo}`);
            refreshAccessTokenAtRegularInterval();
            console.log('trackFields.includes(name)', trackFields.includes('name'));
            // if (trackFields?.includes('name')) {
            //   // if (true) {
            // // await updateWallet(name, x.accessToken);
            // // setShowDetailsForm(true);
            //   return navigate('/profile?loggedIn=true', { state: locationState, disableBack: true });
            // }
            if (locationState?.link) {
              // updateStore('searchedQuery', {
              //   link: state?.from?.pathname + state.from.search,
              // });
              navigate(appendLoggedIn(locationState.link), { state: { ...locationState.nextState } });
            }

            // window.location.reload()
            // navigate(oauthLink())
          }
        }).finally((z) => {
          setIsLoading((prev) => ({ ...prev, submitOTP: false }));
        });
  };

  return {
    states: {
      phNo,
      setPhNo,
      countryCode,
      setCountryCode,
      conditionsAgreed,
      setConditionsAgreed,
      errors,
      setErrors,
      txId,
      setTxId,
      isLoading,
      setIsLoading,
      otp,
      setOtp,
      step,
      setStep,
      otpTimeoutSeconds,
      setOtpTimeoutSeconds,
      accountExists,
      setAccountExists,
      trackFields,
      setTrackFields,
      flagId,
      setFlagId
    },
    handlers: {
      getTimeoutTime,
      startReqOTP,
      handleRequestOTP,
      handleResendOtp,
      handleEnterKeyPress,
      submitOtp,
    },
    misc: {
      phoneRef,
      timerRef
    }
  };
}

export default useAuth;
