const trackingEvents = {
  auth: {
    prefix: 'Auth - ',
    events: {
      sign: {
        in: {
          started: 'Auth - Sign In [Started]',
          requestedOTP: 'Auth - Sign In [OTP Requested]',
          verifiedOTP: 'Auth - Sign In [OTP Verified]',
          completed: 'Auth - Sign In [Completed]',
          failed: 'Auth - Sign In [Failed]',
        },
        out: 'Auth - Sign Out',
        up: {
          started: 'Auth - Sign Up [Started]',
          Responsed: 'Auth - Sign Up [Completed]',
          requestedOTP: 'Auth - Sign In [OTP Requested]',
          verifiedOTP: 'Auth - Sign In [OTP Verified]',
        }
      }
    }
  },
  form: {
    prefix: 'Form - ',
    events: {
      select: {
        opened: 'Form - Opened',
        verificationStarted: 'Form - Verification Started',
        verificationSubmitted: 'Form - Verification Submitted',
        completed: 'Form - Completed'
      },

    }
  },
  verification: {
    prefix: 'Form - ',
    events: {
      customFormsValidationFailed: 'Verification - Custom Form Validation Failed',
      triggerSubmitVerification: 'Verification - Trigger Submit Verification',
      fileManagement: 'Verification - File Management',
      validation: 'Verification - Validation',
      addToProfile: 'Verification - Add To Profile',
      afterTriggerSubmitVerification: 'Verification - After Trigger Submit Verification',
      editing: 'Verification - Editing',
      deleting: 'Verification - Deleting',
      allDone: 'Verification - All Done',
      movingToNextPage: 'Verification - Moving To Next Page',
      uploadFileWithOldVerification: 'Verification - Upload File With Old Verification',
      uploadingFile: 'Utility - Uploading File',
      enquireToEditNameOnTheCertificate: 'Verification - Enquire To Edit Name On The Certificate'
    }

  },
  payment: {
    prefix: 'Payment - ',
    events: {
      transaction: {
        initiationStarted: 'Payment Initiation Started',
        initiationFailed: 'Payment Initiation Failed',
        started: 'Payment Started',
        pending: 'Payment Pending',
        failed: 'Payment Failed',
        redirectionComplete: 'Payment Redirection Completed - Checking Payment Status',
        completed: 'Payment Completed',
        statusAPINotResponded: 'Payment Check Status API not responded'
      },
      step: {
        viewed: 'Payment Page Viewed'
      }
    }
  },
  tawkTo: {
    prefix: 'TawkTo - ',
    events: {
      widgetLoad: 'TawkTo - Widget load',
      widgetStarted: 'TawkTo - Widget started',
      chatStart: 'TawkTo - Chat start',
      chatEnd: 'TawkTo - Chat end',
      chatMessage: 'TawkTo - Chat message',
      chatReplied: 'TawkTo - Chat replied using automated replies',
      chatJoinedBySupport: 'TawkTo - Chat joined by support',
    }
  }
};

export { trackingEvents };

